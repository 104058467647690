import { Component } from 'react';
import Link from 'next/link';
import { chunk } from 'lodash';
import { isExternal } from '~/utils/utils';
import Social from '~/components/social/social';

/**
 * Footer
 * @category Component
 * @extends Component
 * @summary Display the site footer. Assigns menus by the display headings and keys in state
 * @prop {object} data - The data from the app wrappper.
 * @return {Node} React node containing the footer.
 */

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    const { data } = this.props;
    const copy = `TM &amp; &copy; ${ this.getYear() } Big Ticket Television Inc. and CBS Interactive Inc., Paramount companies. All Rights Reserved.`;
    const chunkedMenus = chunk(data.footer.items, 4);
    const LinkDecider = (link) => {
      switch(true) {
        case link.url.includes('donotsell'):
          return <a className="ot-sdk-show-settings" aria-label={link.title}>Your Privacy Choices</a>;
        case isExternal(link.url):
          return <a
            href={ link.url }
            aria-label={`Visit ${ link.title }`}
            rel='noopener noreferrer'
            target='_blank'
          >
            { link.title }
          </a>;
        default:
          return <Link href={ link.url }>
            <a aria-label={`Visit ${ link.title }`}>{ link.title }</a>
          </Link>;
      }
    }

    return (
      <footer className='footer'>
        <div>
          <div className='mobile-only'>
            <Social />
          </div>
          <div className='footer-menu'>
            {chunkedMenus.map((menu, i) => (
              <ul key={`footer-menu-${i}`}>
                {menu.map((link) => (
                  <li key={`footer-menu-item-${link.url}`}>
                    {LinkDecider(link)}
                  </li>
                ))}
              </ul>
            ))}
          </div>
          <div className='mobile-only'>
            <div className='footer-copy' dangerouslySetInnerHTML={{__html: copy}}></div>
          </div>
        </div>
        <div className='footer-right'>
          <div className='desktop-only'>
            <div className='footer-copy' dangerouslySetInnerHTML={{__html: copy}}></div>
            <Social />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
