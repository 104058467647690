import { useEffect } from 'react';
import App from 'next/app';
import Router from 'next/router';
import { useAmp } from 'next/amp'
import Head from 'next/head';
import { request } from 'graphql-request';
import NProgress from 'nprogress';
import Store from '~/store/undux';
import Frame from '~/components/wrappers/frame';
import AmpHeader from '~/components/header/ampHeader';
import AmpFooter from '~/components/footer/ampFooter';
import { has, get } from '~/utils/utils';
import { isDevelopmentMode, enableAxe } from '~/utils/axe';
import '~/sass/app.sass';

// GQL
import MENUS_COMMON from '~/graphql/MENUS_COMMON.graphql';

if (isDevelopmentMode()) {
  enableAxe();
}

// Report Stats
export function reportWebVitals(metric) {
  const reportStats = localStorage.getItem('debugReportStats');

  if(reportStats) {
    console.log(metric);
  }
}

/**
 * Site
 * @category Wrappers
 * @extends App
 * @summary Main wrapper for the entire application. Handles global data such as the main menus and template assignment.
 */

function Site(props) {
  const isAmp = useAmp();
  const {
    Component,
    pageProps,
    router,
    data,
    baseUrl,
    sitePrimaryRsid,
    path,
    themeBypass
  } = props;

  // Assigns loading effects and toggles css animation blocker
  useEffect(() => {
    function pageLoaded() {
      if(!isAmp && !themeBypass) {
        setTimeout(() => {
          document.getElementById('preload').removeAttribute('id');
        }, 100);
      }
    }

    if(!isAmp) {
      window.addEventListener('load', pageLoaded);

      // Loader
      NProgress.configure({
        minimum: 0.3,
        easing: 'ease',
        speed: 800,
        showSpinner: false,
      });

      Router.events.on('routeChangeStart', () => {
        NProgress.start();
      });

      Router.events.on('routeChangeComplete', () => {
        NProgress.done();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });

      Router.events.on('routeChangeError', () => NProgress.done());
    }
    return () => {
      if(!isAmp) {
        window.removeEventListener('load', pageLoaded);
      }
    };
  });

  /* AMP Pages */
  if(isAmp) {
    return(
      <>
        <Head>
          <meta charSet="utf-8" />
        </Head>
        <AmpHeader data={ data } />
        <Component
          { ...pageProps }
          key={ router.asPath }
          router={ router }
          baseUrl={ baseUrl }
        />
        <AmpFooter data={ data } />
      </>
    );
  }

  /* Used to bypass the frame for video embed pages */
  if(themeBypass) {
    return(
      <Component
        { ...pageProps }
        key={ router.asPath }
        router={ router }
      />
    );
  }

  /* Everything Else */
  return (
    <div id='preload'>
      <Store.Container>
        <Frame data={ data }>
          <Component
            { ...pageProps }
            key={ router.asPath }
            router={ router }
            baseUrl={ baseUrl }
            sitePrimaryRsid={ sitePrimaryRsid }
            path={ path }
          />
        </Frame>
      </Store.Container>
    </div>
  );
}

Site.getInitialProps = async (ctx) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx);

  const data = await request(process.env.NEXT_PUBLIC_API_HOST || 'https://api-stage.thedrewbarrymoreshow.com/cmv_graphql/api/query', MENUS_COMMON, {});

  let optionalProps = {};
  if(has(ctx, 'ctx.req.query')) {
    optionalProps = {
      themeBypass: get(ctx, 'ctx.req.query.themeBypass') === '1'
    }
  }

  return {
    ...appProps,
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
    sitePrimaryRsid: process.env.NEXT_PUBLIC_SITE_PRIMARY_RSID,
    path: ctx.router.asPath,
    data,
    ...optionalProps
  }
}

export default Site;