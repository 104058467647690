import key from 'weak-key';

/**
 * Amp Header
 * @category Functional Component
 * @extends Component
 * @summary Display the AMP header / main menu.
 * @prop {object} data - The data from the app wrappper.
 * @return {Node} React node containing AMP header / main menu.
 */

const AmpHeader = (props) => {
  const { data } = props;
  const getMenu = items => {
    const menu = items.map((item) => {
      return(
        <li key={`amp-menu-item-${key(item)}`}>
          { !item.below || item.below.length === 0
            ? <a className='amp-menu__main-item' href={ item.url }>{ item.title }</a>
            : <>
              <h4 className='amp-menu__main-item-heading'>{ item.title }</h4>
              { getMenu(item.below) }
            </>
          }
        </li>
      );
    });

    return(
      <ul className='amp-menu__main'>
        { menu }
      </ul>
    );
  }

  return (
    <div>
      <header className='amp-menu__bar'>
        <button type='button' on='tap:amp-menu__sidebar' className='amp-menu__open'>
          <svg viewBox='0 0 33 27' className='amp-menu__open-icon'>
            <path d='m31.0913584 3.867125h-29.25870752c-1.007325 0-1.8322425-.7575-1.8322425-1.68375s.8249175-1.6825 1.8322425-1.6825h29.25870752c1.0086862 0 1.8336037.75625 1.8336037 1.6825s-.8249175 1.68375-1.8336037 1.68375' />
            <path d='m31.0913584 3.433625h-29.25870753c-1.00732499 0-1.83224249-.7575-1.83224249-1.68375 0-.925.8249175-1.6825 1.83224249-1.6825h29.25870753c1.0086862 0 1.8336037.7575 1.8336037 1.6825 0 .92625-.8249175 1.68375-1.8336037 1.68375' transform='translate(0 12)'/>
            <path d='m31.0913584 4h-29.25870752c-1.007325 0-1.8322425-.80666667-1.8322425-1.796 0-.98666667.8249175-1.79466667 1.8322425-1.79466667h29.25870752c1.0086862 0 1.8336037.808 1.8336037 1.79466667 0 .98933333-.8249175 1.796-1.8336037 1.796' transform='translate(0 23)'/>
          </svg>
        </button>
        <a href='/'>
          <amp-img
            className='amp-menu__logo'
            width='82'
            height='55'
            alt='The Drew Barrymore Show'
            src='/img/drew-logo.svg'
            layout='responsive'
          ></amp-img>
        </a>
      </header>
      <amp-sidebar id='amp-menu__sidebar' layout='nodisplay'>
        <a className='amp-menu__logo-sidebar' href='/'>
          <div className='amp-menu__logo-sidebar-circle'>
            <amp-img
              width='156'
              height='156'
              alt='The Drew Barrymore Show'
              src='/img/drew-logo.svg'
              layout='responsive'
            ></amp-img>
          </div>
        </a>
        { data.mainmenu && getMenu(data.mainmenu.items) }
      </amp-sidebar>
    </div>
  );
}

export default AmpHeader;
