import key from 'weak-key';

/**
 * Amp Footer
 * @category Functional Component
 * @extends Component
 * @summary Display the AMP header / main menu.
 * @prop {object} data - The data from the app wrappper.
 * @return {Node} React node containing AMP header / main menu.
 */

const AmpFooter = (props) => {
  const { data } = props;
  const getYear = () => {
    return new Date().getFullYear();
  }

  return (
    <div>
      <footer className='amp-footer'>
        <a href='/'>
          <amp-img
            className='amp-footer__logo'
            width='82'
            height='55'
            alt='The Drew Barrymore Show'
            src='/img/drew-logo.svg'
            layout='responsive'
          ></amp-img>
        </a>
        <ul className='amp-footer__menu'>
          { data?.footer?.items && data.footer.items.map((item) => {
              return(
                <li key={`footer-item-${key(item)}`}>
                  <a className='amp-menu__main-item' href={ item.url }>
                    { item.title }
                  </a>
                </li>
              );
            })
          }
        </ul>
        <div className='amp-footer__copy'>
          TM &amp; &copy; { getYear() } Big Ticket Television Inc. and CBS Interactive Inc., Paramount companies. All Rights Reserved.
        </div>
      </footer>
    </div>
  );
}

export default AmpFooter;
