import { ReactSVG } from 'react-svg';
import key from 'weak-key';
import data from '~/store/state';
import LinkTracker from '~/components/linkTracker/linkTracker';

/**
 * Social
 * @category Functional Component
 * @extends Component
 * @summary Display the social links.
 * @prop {string} theme - The theme. Accepts "dark".
 * @prop {string} iconStyle - The icon style. Accepts "isolated".
 * @return {Node} React node containing social links.
 */

const Social = (props) => {
  const { theme, iconStyle } = props;

  return (
    <ul className={ theme === 'dark' ? 'social social--dark' : 'social' }>
      {data.social.map((item) => (
        <li key={`social-item-${key(item)}`}>
          <LinkTracker
            destinationUrl={ item.href }
            linkCta={ item.label.toLowerCase() }
            linkFormat='button'
            linkRegion='main menu'
            linkSubRegion='social'
          >
            <a
              aria-label={`Visit ${ item.label }`}
              href={ item.href }
              target={ item.target }
              rel='noopener noreferrer'
              data-autotrack='false'
            >
              <ReactSVG src={iconStyle === 'isolated' ? item.iconIsolated : item.icon} />
              { item.target === '_blank' && <span className='visuallyhidden'>Opens a new window</span>}
            </a>
          </LinkTracker>
        </li>
      ))}
    </ul>
  );
}

export default Social;
