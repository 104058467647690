/* global om */
import { useRouter } from 'next/router';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { verifyOm } from '~/utils/utils';

/**
 * Search
 * @category Functional Component
 * @extends Component
 * @summary Display the search bar.
 * @prop {string} theme The theme to display, accepts 'page'.
 * @prop {string} term The search term.
 * @return {Node} React node containing the search bar.
 */

const Search = props => {
  const { theme, term = '' } = props;
  const [searchTerm, setSearchTerm] = useState(term);
  const router = useRouter();

  const changeSearch = e => {
    setSearchTerm(e.target.value);
  };

  const clear = () => {
    setSearchTerm('');
  };

  const search = async e => {
    if(searchTerm) {
      const isAnalyticsVerified = await verifyOm(100, 10);
      if (isAnalyticsVerified) {
        om.trackAction('trackSearchSubmit', {
          searchTerm
        });
      }

      setTimeout(() => {
        router.push(`/search?q=${ encodeURIComponent(searchTerm) }`);
      },100);
    }
  };

  const handleEnter = e => {
    if(e.charCode == 13) {
      search();
    }
  };

  if(theme === 'page') {
    return (
      <div className='search-bar search-bar--page'>
        <div className='search-bar__grid'>
          <div className='wrap-relative'>
            <label htmlFor='search'>
              <span className='visuallyhidden'>Search</span>
              <input
                id='search'
                type='text'
                placeholder='search'
                onKeyPress={ handleEnter }
                onChange={ changeSearch }
                value={ searchTerm }
              />
            </label>
            <button
              aria-label='Search'
              type='button'
              className='search-bar__icon'
              onClick={ search }
            >
              <ReactSVG src='/img/icons/search.svg' />
            </button>
            { searchTerm &&
              <button
                aria-label='Clear'
                type='button'
                className='search-bar__clear'
                onClick={ clear }
              >
                <ReactSVG src='/img/icons/close-thin.svg' />
              </button>
            }
          </div>
          <div>
            <button
              aria-label='Search'
              type='button'
              className='search-bar__btn'
              onClick={ search }
            >
              search
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='search-bar'>
      <button
        aria-label='Search'
        type='button'
        className='search-bar__icon'
        onClick={ search }
      >
        <ReactSVG src='/img/icons/search.svg' />
      </button>
      <label htmlFor='search'>
        <span className='visuallyhidden'>Search</span>
        <input
          id='search'
          type='text'
          placeholder='search'
          onKeyPress={ handleEnter }
          onChange={ changeSearch }
          value={ searchTerm }
        />
      </label>
    </div>
  );
}

export default Search;
