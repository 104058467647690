/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';
import Store from '~/store/undux';
import Header from '~/components/header/header';
import Footer from '~/components/footer/footer';

/**
 * Frame
 * @category Wrappers
 * @extends Component
 * @summary Main site wrapper, this compoponent contains the header and the footer.
 * @prop {object} data - The data from the app wrappper.
 * @return {Node} React node containing the site frame.
 */

class Frame extends Component {
  render() {
    const { children, data, store } = this.props;

    return (
      <div className='wrap-relative'>
        <div
          id='sticky-banner'
          className={ store.get('stickyBanner') ? store.get('stickyBanner') : '' }
        >
          <div data-ad-ref='omni-skybox-plus-sticky' className='bidbarrel-ad'></div>
          <div data-ad-ref='mobile-omni-skybox-plus-sticky' className='bidbarrel-ad'></div>
        </div>
        <div className='wrap-relative'>
          <Header data={ data } />
          <div className='wrap-site'>
            <main>
              { children }
            </main>
            <Footer data={ data } />
          </div>
        </div>
      </div>
    );
  }
}

export default Store.withStore(Frame);
