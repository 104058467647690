const state = {
  social: [
    {
      label: 'Facebook',
      href: 'https://www.facebook.com/TheDrewBarrymoreShow',
      target: '_blank',
      title: 'Visit Drew on Facebook',
      icon: '/img/icons/facebook.svg',
      iconIsolated: '/img/icons/facebook-iso.svg'
    },
    {
      label: 'Instagram',
      href: 'https://www.instagram.com/TheDrewBarrymoreShow/',
      target: '_blank',
      title: 'Visit Drew on Instagram',
      icon: '/img/icons/instagram.svg',
      iconIsolated: '/img/icons/instagram-iso.svg'
    },
    {
      label: 'Youtube',
      href: 'https://www.youtube.com/TheDrewBarrymoreShow',
      target: '_blank',
      title: 'Visit Drew on Youtube',
      icon: '/img/icons/youtube.svg',
      iconIsolated: '/img/icons/youtube-iso.svg'
    },
    {
      label: 'TikTok',
      href: 'https://www.tiktok.com/@thedrewbarrymoreshow',
      target: '_blank',
      title: 'Visit Drew on TikTok',
      icon: '/img/icons/tiktok.svg',
      iconIsolated: '/img/icons/tiktok-iso.svg'
    },
    {
      label: 'Giphy',
      href: 'https://giphy.com/thedrewbarrymoreshow/',
      target: '_blank',
      title: 'Visit Drew on Giphy',
      icon: '/img/icons/giphy.svg',
      iconIsolated: '/img/icons/giphy-iso.svg'
    }
  ]
};
export default state;