import { Component, Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import key from 'weak-key';
import { withRouter } from 'next/router';
import StickyBox from 'react-sticky-box';
import { Scrollbars } from 'react-custom-scrollbars';
import Store from '~/store/undux';
import LinkTracker from '~/components/linkTracker/linkTracker';
import Search from '~/components/search/search';
import SiteSettings from '~/siteSettings';
import ScrollTrigger from '~/components/scrollTrigger/scrollTrigger';
import Social from '~/components/social/social';

/**
 * Header
 * @category Component
 * @extends Component
 * @summary Display the site header.
 * @prop {object} data - The data from the app wrappper.
 * @return {Node} React node containing the header.
 */

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuToggled: false,
      isSubMenuToggled: false,
      isMenuInView: true,
      isMobile: true,
      showSubMenu: false,
      episodeSchedule: props?.data?.episode_schedule?.resultRow[0]
    };
    this.tick = false;
  }

  componentDidMount() {
    const { router } = this.props;

    router.events.on('routeChangeComplete', () => {
      const { store } = this.props;
      const setStickyBanner = store.set('stickyBanner');
      const setMenuOffset = store.set('mainMenuOffset');

      setStickyBanner(false);
      setMenuOffset(0);
    });

    router.events.on('routeChangeStart', () => {
      const { isMenuToggled } = this.state;

      if(isMenuToggled) {
        this.setState({
          isMenuToggled: false
        });
      }
    });

    window.addEventListener('resize', this.onResize, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, true);
  }

  onResize = () => {
    const width = window.innerWidth;

    if (!this.tick) {
      window.requestAnimationFrame(() => {
        this.setState({
          isMobile: width < SiteSettings.jsBreakpoints.xs
        })
        this.tick = false;
      });

      this.tick = true;
    }
  }

  toggleSubmenu = parentMenuID => {
    const { isSubMenuToggled} = this.state;
    this.setState({
      showSubMenu: parentMenuID,
      isSubMenuToggled: !isSubMenuToggled,
    });
  };

  getIcon = icon => {
    const title = icon.toLowerCase().split(' ').join('-').replace('\'', '');
    const icons = [
      'bananamores',
      'show-info',
      'videos',
      'from-the-show',
      'shop',
      'podcasts',
      'podcast',
      'share-your-story'
    ];

    if(!icons.includes(title)) {
      return('/img/icons/generic.svg');
    }

    return(`/img/icons/${ title }.svg`);
  }

  isMenuInView = (isMenuInView) => {
    this.setState({
      isMenuInView
    });
  }

  toggleMenu = e => {
    const { isMenuToggled, isMobile } = this.state;

    // Focus Search on Open
    if(!isMenuToggled && !isMobile || !isMenuToggled && e?.currentTarget?.dataset?.searchtoggle === 'true') {
      document?.getElementById('search')?.focus?.();
    }

    this.setState({
      isMenuToggled: !isMenuToggled
    });
  }

  render() {
    const { showSubMenu, isMenuToggled, isSubMenuToggled, isMenuInView, episodeSchedule } = this.state;
    const { data, router, store } = this.props;
    const showInterior = router.pathname !== '/' && !router.pathname.includes('content-hub/');

    return <>
      { !showInterior &&
        <ScrollTrigger triggerAt={ 200 } action={ this.isMenuInView } />
      }
      <StickyBox
        className='main-menu__sticky'
        enabled='true'
        offsetTop={ store.get('mainMenuOffset') }
        offsetBottom={ 0 }
        style={{ top: store.get('mainMenuOffset') }}
      >
        <header id='header' className={ showInterior ? 'main-menu__interior' : null }>
          <div className={`main-menu__logo${ isMenuToggled || !isMenuInView ? ' main-menu__logo--toggled' : '' }`}>
            <LinkTracker
              destinationUrl='/'
              linkCta='drew logo'
              linkFormat='image'
              linkRegion='main menu'
              linkSubRegion='main'
            >
              <a
                className='btn-reset'
                type='button'
                aria-label='Visit DREW Home'
                href='/'
                data-autotrack='false'
              >
                <ReactSVG
                  className={`main-menu__logo__circle${ !isMenuInView ? ' main-menu__logo__circle--toggled' : '' }`}
                  src='/img/drew-logo.svg'
                />
              </a>
            </LinkTracker>
          </div>
          <div id='main-menu__secondary' className={`main-menu__secondary${ !isMenuInView ? ' main-menu__secondary--toggled' : '' }`}>
            <LinkTracker
              destinationUrl='/'
              linkCta='drew logo'
              linkFormat='image'
              linkRegion='main menu'
              linkSubRegion='main'
            >
              <a
                className='btn-reset drew-logo'
                type='button'
                href='/'
                aria-label='Visit DREW Home'
                data-autotrack='false'
              >
                <ReactSVG src='/img/drew-logo.svg' />
              </a>
            </LinkTracker>
          </div>
          <button
            aria-label='Open Menu'
            type='button'
            onClick={ this.toggleMenu }
            className='main-menu__toggle main-menu__toggle--primary'
          >
            <ReactSVG  src='/img/icons/menu.svg' />
          </button>
          <a
            type='button'
            aria-label='Search'
            className='main-menu__search'
            data-searchtoggle='true'
            onClick={ this.toggleMenu }
          >
            <ReactSVG src='/img/icons/search-menu.svg' />
          </a>
        </header>
      </StickyBox>
      <div id='main-menu__sidebar' className={`main-menu__sidebar${ isMenuToggled ? ' main-menu__sidebar--toggled' : '' }`}>
        <Scrollbars
          universal
          className='main-menu__sidebar-scroll-region'
          renderThumbVertical={props => <div {...props} className="main-menu__sidebar-scroll-region__thumb-v"/>}
          autoHide
          autoHideTimeout={1000}
        >
          <div className='main-menu__logo main-menu__logo--sidebar'>
            <LinkTracker
              destinationUrl='/'
              linkCta='drew logo'
              linkFormat='image'
              linkRegion='main menu'
              linkSubRegion='main'
            >
              <a
                className='btn-reset'
                type='button'
                aria-label='Visit DREW Home'
                href='/'
                data-autotrack='false'
              >
                <ReactSVG className='main-menu__logo-sidebar' src='/img/drew-logo.svg' />
              </a>
            </LinkTracker>
          </div>
          <div className='main-menu__sidebar-spacer'/>
          <nav className='main-menu__sidebar-section' itemScope itemType='http://www.schema.org/SiteNavigationElement'>
            { process.env.NEXT_PUBLIC_ENABLE_SEARCH === '1' &&
              <Search />
            }
            <ul className='main-menu__sidebar-section-menu'>
              { data?.mainmenu && data.mainmenu.items.map((item) => (
                <li key={`menu-item-${key(item)}`}>
                  { item?.below?.length > 0 &&
                    <div>
                      <LinkTracker
                        destinationUrl=''
                        linkCta={ item.title.toLowerCase() }
                        linkFormat='button'
                        linkRegion='main menu'
                        linkSubRegion='main'
                      >
                        <button
                          aria-label={item.title}
                          type='button'
                          onClick={() => this.toggleSubmenu(`${item.title.split(' ').join('-')}--button`)}
                          className='main-menu__toggle--subnav'
                          id={`${item.title.split(' ').join('-')}--button`}
                          aria-expanded='false'
                          itemProp='url'
                          data-autotrack='false'
                        >
                          <ReactSVG src={ this.getIcon(item.title) } />
                          <span itemProp='name'>
                            { item.title }
                          </span>
                        </button>
                      </LinkTracker>
                      <ul className='main-menu__sidebar--subnav'>
                        <div className={`main-menu__submenu${ isSubMenuToggled && showSubMenu === `${item.title.split(' ').join('-')}--button` ? ' ' : ' visuallyhidden' }`}>
                          { item?.below?.map((submenu) => (
                            <li key={`sub-menu-item-${key(submenu)}`}>
                              <LinkTracker
                                destinationUrl={ submenu.title === 'episode schedule' && episodeSchedule.path ? episodeSchedule.path : submenu.url }
                                linkCta={ submenu.title.toLowerCase() }
                                linkFormat='text'
                                linkRegion='main menu'
                                linkSubRegion={`main - ${ item.title.toLowerCase() }`}
                              >
                                <a
                                  href={ submenu.title === 'episode schedule' && episodeSchedule.path ? episodeSchedule.path : submenu.url }
                                  aria-label={ submenu.title }
                                  rel='noopener noreferrer'
                                  itemProp='url'
                                  data-autotrack='false'
                                >
                                  <span>
                                    { submenu.title }
                                  </span>
                                </a>
                              </LinkTracker>
                            </li>
                          ))}
                        </div>
                      </ul>
                    </div>
                  }
                  { item.below.length === 0 &&
                    <LinkTracker
                      destinationUrl={ item.title === 'episode schedule' && episodeSchedule.path ? episodeSchedule.path : item.url }
                      linkCta={ item.title.toLowerCase() }
                      linkFormat='button'
                      linkRegion='main menu'
                      linkSubRegion='main'
                    >
                      <a
                        className='btn-reset'
                        type='button'
                        aria-label={ item.title }
                        href={ item.title === 'episode schedule' && episodeSchedule.path ? episodeSchedule.path : item.url }
                        itemProp='url'
                        content={ item.title === 'episode schedule' && episodeSchedule.path ? episodeSchedule.path : item.url }
                        data-autotrack='false'
                      >
                        <ReactSVG src={ this.getIcon(item.title) } />
                        <span itemProp='name'>
                          { item.title }
                        </span>
                      </a>
                    </LinkTracker>
                  }
                </li>
              ))}
            </ul>
          </nav>
          {/* Content Hub Menus */}
          { data?.hubs &&
            <div className='main-menu__hubs' itemScope itemType='http://www.schema.org/SiteNavigationElement'>
              { data?.hubs?.items && data.hubs.items.map((item) => (
                <Fragment key={`ch-menu-${ key(item) }`}>
                  <nav>
                    <h3>
                      <LinkTracker
                        destinationUrl={ item.url }
                        linkCta={ item.title.toLowerCase() }
                        linkFormat='text'
                        linkRegion='main menu'
                        linkSubRegion={`hubs - ${ item.title.toLowerCase() }`}
                      >
                        <a
                          type='text'
                          title={ item.title }
                          aria-label={ item.title }
                          href={ item.url }
                          data-autotrack='false'
                        >
                          <span itemProp='name'>
                            { item.title }
                          </span>
                        </a>
                      </LinkTracker>
                    </h3>
                    <ul>
                      { item.below.length && item.below.map((subitem) => (
                        <li key={`ch-sub-menu-${ key(subitem) }`}>
                          <LinkTracker
                            destinationUrl={ subitem.url }
                            linkCta={ subitem.title.toLowerCase() }
                            linkFormat='text'
                            linkRegion='main menu'
                            linkSubRegion={`hubs - ${ item.title.toLowerCase() }`}
                          >
                            <a
                              type='text'
                              title={ subitem.title }
                              aria-label={ subitem.title }
                              href={ subitem.url }
                              data-autotrack='false'
                            >
                              <span itemProp='name'>
                                { subitem.title }
                              </span>
                            </a>
                          </LinkTracker>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Fragment>
              ))}
            </div>
          }
          <nav className='main-menu__sidebar-section main-menu__sidebar-section--supplemental'>
            <Social data={ data } theme='dark' iconStyle='isolated' />
          </nav>
          <button
            aria-label='Open Menu'
            type='button'
            onClick={ this.toggleMenu }
            className='main-menu__toggle main-menu__toggle--sidebar'
          >
            <ReactSVG src='/img/icons/mobile-close.svg' />
          </button>
        </Scrollbars>
      </div>
      <button
        aria-label='Open Menu'
        type='button'
        onClick={this.toggleMenu}
        className={`main-menu__sidebar-mask${isMenuToggled ? ' main-menu__sidebar-mask--toggled' : ''}`}
      />
    </>
  }
}

export default withRouter(Store.withStore(Header));
