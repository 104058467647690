import { Component } from 'react';
import { supportsPassive } from '~/utils/utils';

/**
 * Scroll Trigger
 * @category Component
 * @extends Component
 * @summary Displays the scroll trigger component.
 * @param {int} triggerAt The px opffset from top to fire at.
 * @param {function} action The function to fire.
 * @return {Node} React node containing the scroll trigger component.
 */

class ScrollTrigger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasTriggered: null
    };
    this.scrollPos = 0;
    this.tick = false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, supportsPassive ? { passive: true } : false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll = () => {
    const { hasTriggered } = this.state;
    const { triggerAt, action } = this.props;

    this.scrollPos = document.body.scrollTop || window.scrollY;

    if (!this.tick) {
      window.requestAnimationFrame(() => {
        if(this.scrollPos >= triggerAt && !hasTriggered || this.scrollPos >= triggerAt && hasTriggered === null) {

          this.setState({
            hasTriggered: true
          });

          action(false);
        }

        if(this.scrollPos < triggerAt && hasTriggered || this.scrollPos < triggerAt && hasTriggered === null) {
          this.setState({
            hasTriggered: false
          });

          action(true);
        }

        this.tick = false;
      });

      this.tick = true;
    }
  }

  render() {
    return(<></>);
  }
}

export default ScrollTrigger;